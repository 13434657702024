/* ProjectDisplay.css */
.card {
    transition: transform 0.3s ease-in-out;
    will-change: transform;
    background-color: var(--card-bg-color); /* Use CSS variables for easy theme changes */
    color: var(--text-color);
}
.card-footer {
   color: white!important;
}

.card:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.card-img-top {
    /* Consider adding a hover effect to zoom the image */
    transition: transform 0.3s ease-in-out;
}

.card-img-top:hover {
    transform: scale(1.05);
}

.card-title {
    font-weight: bold;
    color: var(--highlight-color);
}

.card-text {
    padding: 1rem; /* Give more space to the text */
}

.btn-custom {
    color: var(--text-color);
    border-color: var(--text-color);
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.btn-custom:hover {
    background-color: var(--text-color);
    color: var(--card-bg-color);
}

/* Define your variables in :root or a theme provider */
:root {
    --card-bg-color: #1E4D2B; /* Olive green background */
    --text-color: #ffffff; /* White text */
    --highlight-color: #C5B783; /* Highlight color for titles */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .card {
        margin: 0.5rem;
    }
}

/* Other styles here... */
