.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: #ffffff;
}

.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left; /* Align text to the left */
  background-color: #1E4D2B;
}

.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 80px;
  color: #C5B783;
  height: 50px;
}

.about .prompt {
  width: 40%;
  font-size: 30px;
  color: #ffffff;
}

.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: #ffffff;
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /*boulder black for background*/
    background-color: #020203;
}

.skills .list {
  list-style: none;
  width: 60%;
}

.list span {
  font-size: 20px;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .about h2, .skills h1, .skills h2 {
    font-size: 30px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .skills {
    text-align: center;
  }

  .list {
    padding: 0;
  }
}
/* Add this to center-align the text within the entire .skills section */
.skills .card {
  background-color: #020203;
  color: inherit;
  text-align: center; /* Center-align text for all cards */
}
/*make headers gold and text white*/
.skills .card h2 {
  color: #C5B783;
  font-size: 40px;
  margin: 0;
  padding: 0;
}
/* Adjust the .list class to use flexbox and center the items */
.skills .list {
  list-style: none;
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center-align items horizontally */
  justify-content: center; /* Center-align items vertically */
  width: 100%; /* Use the full width */
  padding: 0; /* Remove padding */
}

/* Center-align the individual items */
.list .item {
  width: 100%; /* Full width to center-align within the parent */
}

/* Update media queries as necessary */
@media only screen and (max-width: 600px) {
  .about h2, .skills h1, .skills h2 {
    font-size: 30px;
  }

  .about .prompt, .skills .list {
    width: 100%; /* Full width for smaller screens */
  }
}