.chess-container {
    color: white;
    margin-right: 50px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
body {
    position: relative;
}

.controls {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.moves-list {
    color: white;
    margin-top: 10px;
    display: inline-block;
    text-align: left;
    max-height: 400px;
    overflow-y: auto;
}
/*if on mobile margin left more*/
@media only screen and (max-width: 600px) {
    .chess-container {
        margin-left: 50px;
    }
}